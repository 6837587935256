// *******************************************************************
//
//		BUTTONS
//
// *******************************************************************

.button-push-effect {
    &:active {
        @include scale(0.9);
        @include transition(all 0.2s);
    }
}

a.btn-loop {
    background-color: $color-loop;
    color: white;
    text-decoration: none;
    font-family: $font-heading;
    font-size: 1.4rem;
    line-height: 1;
    padding: 10px 22px 8px 22px;
    display: inline-block;
    @include border-radius($border-radius-form-input);
    cursor: pointer;
    @include transition(background-color 0.2s);

    &:hover {
        background-color: darken(#ed008c, 8%);
        @include transition(background-color 0.2s);
    }
}

// Button Generell
.btn-default,
.btn-primary:not(p-button),
p-button.btn-primary > button {
    display: inline-block;
    text-decoration: none;
    font-family: $font-heading;
    font-size: 1.1em;
    line-height: 1;
    padding: .65em 1em .5em 1em;
    @include border-radius($border-radius-form-input);
    @include transition(all .15s ease-in-out);

    &:hover {
        @include transform(scale(1.02));
    }

    .p-button-icon, i {
        //font-size: 1em;

        &:before {
            font-weight: 300;
        }
    }

    span.mobile-text {
        display: none;
    }
}

.btn-primary:not(p-button),
p-button.btn-primary > button {
    border: 1px solid $color-text-main;
    background-color: $buttonBg;
    color: $buttonTextColor;

    i {
        margin-right: .5em;
    }

    &:hover {
        background-color: $color-loop;
        border-color: $color-loop;
    }
}

.btn-default {
    border: 1px solid darken($color-default, 12%);
    background-color: white;
    color: $color-text-main;

    &:hover {
        border-color: darken($color-default, 25%);
    }

    // Effekte
    @keyframes pulse {
        0% {
            @include transform(scale(1.06));
        }

        60% {
            @include transform(scale(1));
        }

        100% {
            @include transform(scale(1.06));
        }
    }

    &.effect-pulse {
        @include transform(scale(1));
        @include animation(pulse 2s infinite);
    }


}
