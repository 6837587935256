// *******************************************************************
//
//		ACCOUNT GLOBAL SCSS
//
//      src/resources/styles/app/frame/_account.scss
//
// *******************************************************************


#page {
    display: block;
    position: relative;
    width: 100%;
    min-height: calc(100vh - var(--height-navbar-top));
    background-color: white;
    padding: var(--height-navbar-top) 0 0 var(--width-sidenav);
    @include transition(all .25s ease-in-out);

    .container {
        display: grid;
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--height-navbar-top));
        line-height: normal;

        app-text-box > div[class^="box"] {
            padding: 0;
            border: none;
        }

        div[class^="box"],
        app-text-box {
            position: relative;
            padding: var(--padding-box);
            border: 1px solid lighten(black, 88%);
            border-top: 0;
            border-left: 0;
            background-color: white;

            // Trackbar innerhalb der Boxen bei Overflow

            &::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
                background: white;
            }

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                @include border-radius(0);
            }

            a:not(.event):not(.default) {
                position: relative;
                //display: inline-block;

                &:hover::after {
                    width: 0;
                }
            }


            .inner {

                &.important {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        display: block;
                        top: calc(-1 * var(--padding-box));
                        left: calc(-1 * var(--padding-box));
                        right: calc(-1 * var(--padding-box));
                        bottom: calc(-1 * var(--padding-box));
                        background-color: $color-primary;
                        z-index: 0;
                    }

                    > * {
                        position: relative;
                        z-index: 1 !important
                    }

                    .title,
                    .content {
                        color: white !important
                    }

                    .title {
                        &::before {
                            content: '\f848';
                            font-family: $icon-fa;
                            font-weight: 900;
                            margin-right: 10px;
                            color: $color-danger;
                        }
                    }

                    a.btn-default {
                        color: white;
                        background-color: $color-danger;
                        border: 1px solid $color-primary;
                        @include transition(all .2s ease-in-out);

                        &:hover {
                            background-color: white;
                            color: $color-danger;
                        }
                    }

                }
            }

            .cta-buttons {
                @include flexbox;
                @include flex-flow(wrap row);
                gap: 10px;
                margin-top: 1em;
            }

            .wrapper.scroll-box {
                height: auto;
                position: relative;

                > .inner {
                    height: 450px;
                    overflow-y: scroll;
                    position: relative;
                    padding-bottom: 40px;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        // box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
                        background: $color-default;
                        @include border-radius(6px);
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: darken($color-default, 15%);
                        @include border-radius(6px);
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    left: 0;
                    right: 12px;
                    bottom: 0;
                    height: 80px;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                }


            }
        }
    }
}


//  TOPNAV
// *******************************************************************

#topnav,
#sidenav {
    font-weight: 600;
}

#topnav {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-primary;
    z-index: 900;
    color: white;
    padding: 0 calc(var(--padding-box) / 2) 0 var(--width-sidenav);
    height: var(--height-navbar-top);

    .inner {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        height: 100%;
        width: 100%;

        a.userinfo {
            @include border-radius(4px);
            padding: 6px 16px;
            color: #b4b4b4;
            text-decoration: none;

            &:hover {
                background: lighten(black, 12%);
                color: white;
            }

            i {
                margin-left: 8px;
            }
        }

        .logo {
            display: inline-block;

            img {
                height: calc(var(--height-navbar-top) - 18px);
                width: auto;
            }

            a {
                display: none;
            }
        }
    }
}


//  SIDENAV
// *******************************************************************

#sidenav {
    position: fixed;
    @include transition(all .25s ease-in-out);
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--width-sidenav);
    background-color: $color-primary;
    z-index: 901;
    padding-top: 20px;

    // Bei geringerer Bildschirmbreite wird die NavBar ausgeblendet
    // Als Helfer Klasse dient "show" um sie wieder einzublenden
    &.show {
        @include transform(translateX(0));

        > button.toggle {
            //background-color: white;

            &::before {
                //color: black;
                content: '\f323';
            }
        }
    }

    button.toggle {
        display: none;
        position: absolute;
        height: var(--height-navbar-top);
        width: calc(1.2 * var(--height-navbar-top));
        background-color: $color-primary;
        z-index: 10000;
        right: calc(-1 * (1.2 * var(--height-navbar-top)));
        top: 0;
        border: none;
        outline: none;
        border-right: 1px solid lighten($color-primary, 20%);
        font-family: $icon-fa;
        color: lighten($color-primary, 60%);
        text-align: center;
        line-height: var(--height-navbar-top);
        font-size: 1.1em;
        @include transition(all .25s ease-in-out);
        cursor: pointer;

        &:hover {
            color: white;
        }

        &::before {
            content: '\f324';
        }

    }

    .inner {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(space-between);
        height: 100%;
        overflow-y: scroll;
        @include hide-scrollbar;
    }

    .logo {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 10px;

        a {
            display: inline-block;
            position: relative;
            width: calc(100% - 50px);
        }

    }

    ul.sidenav-menu {
        width: 100%;
        padding: 0 1.5em;

        li.label {
            color: white;
            font-size: 1.05em;
            font-weight: 700;
            margin-bottom: .5em;
        }

        li.sidenav-menu-link {
            padding-left: .5em;

            + li.label {
                margin-top: 1em;
            }
        }

        a {
            @include flexbox;
            @include align-items(center);
            gap: 6px;
            position: relative;
            color: fade-out(white, .5);
            text-decoration: none;
            line-height: 1;
            padding: .5em;
            font-weight: 400;

            &:hover {
                color: white;
            }
        }
    }


    .footer {

        --border-top: 1px solid rgba(255, 255, 255, 0.15);

        display: block;
        width: var(--width-sidenav);

        .version {
            text-align: center;
            line-height: 1.5;
            padding: 1.2em 2em;
            font-size: 13px;
        }

        .lang-logout {
            @include flexbox;
            @include justify-content(space-between);
            border-top: var(--border-top);
            padding: 0;

            a {
                color: white;
                text-decoration: none;
                padding: 1.5em;

                &:hover {
                    background: lighten(black, 12%);
                }
            }
        }

        .app-copy {
            border-top: var(--border-top);
            @include flexbox;
            @include flex-flow(wrap row);
            @include justify-content(center);
            gap: 4px;
            font-size: 12px;
            padding: 1.2em 1em;

            a {
                color: white;
                text-decoration: none;
                white-space: nowrap;
                font-weight: 400;
                @include transition(color .25s ease-in-out);

                &:hover {
                    color: $color-loop;
                }
            }
        }
    }
}

