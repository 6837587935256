// *******************************************************
//
//    XDPRO Core Mixins
//
// *******************************************************

@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin text-shadow($string: 0 1px 2px rgba(0, 0, 0, 0.25)) {
    text-shadow: $string;
}

@mixin backdrop-filter($filter) {
    -webkit-backdrop-filter: $filter;
    backdrop-filter: $filter;
}

@mixin line-clamp($string: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $string;
    line-clamp: $string;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
}

@mixin box-shadow($string) {
    -webkit-box-shadow: $string;
    -moz-box-shadow: $string;
    box-shadow: $string;
}

@mixin drop-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
    -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin inner-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
    -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin box-sizing($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    box-sizing: $type;
}

@mixin border-radius($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -khtml-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-radiuses(
    $topright: 0,
    $bottomright: 0,
    $bottomleft: 0,
    $topleft: 0
) {
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    border-top-left-radius: $topleft;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

@mixin background-clip() {
    -moz-background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
}

@mixin opacity($opacity: 0.5) {
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
    opacity: $opacity;
}

@mixin gradient($startColor: #eee, $endColor: white) {
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0.05, $startColor),
            color-stop(1, $endColor)
    );
    background: -webkit-linear-gradient(top, $startColor 0%, $endColor 100%);
    background: -moz-linear-gradient(top, $startColor 0%, $endColor 100%);
    background: -ms-linear-gradient(top, $startColor 0%, $endColor 100%);
    background: -o-linear-gradient(top, $startColor 0%, $endColor 100%);
    background: linear-gradient(top bottom, $startColor 0%, $endColor 100%);
    background-color: $startColor;
}

@mixin gradient-deg($startPos: 135deg, $startColor: #eee, $endColor: white) {
    background: -webkit-gradient(
            linear,
            $startPos,
            $startPos,
            color-stop(0.05, $startColor),
            color-stop(1, $endColor)
    );
    background: -webkit-linear-gradient(
            $startPos,
            $startColor 0%,
            $endColor 100%
    );
    background: -moz-linear-gradient($startPos, $startColor 0%, $endColor 100%);
    background: -ms-linear-gradient($startPos, $startColor 0%, $endColor 100%);
    background: -o-linear-gradient($startPos, $startColor 0%, $endColor 100%);
    background: linear-gradient($startPos, $startColor 0%, $endColor 100%);
    background-color: $startColor;
}

@mixin horizontal-gradient($startColor: #eee, $endColor: white) {
    background-color: $startColor;
    background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            from($startColor),
            to($endColor)
    );
    background-image: -webkit-linear-gradient(left, $startColor, $endColor);
    background-image: -moz-linear-gradient(left, $startColor, $endColor);
    background-image: -ms-linear-gradient(left, $startColor, $endColor);
    background-image: -o-linear-gradient(left, $startColor, $endColor);
}

@mixin backface-visibility($string: hidden) {
    -webkit-backface-visibility: $string;
    -moz-backface-visibility: $string;
    backface-visibility: $string;
}

@mixin background-image($value: contain) {
    -webkit-background-size: $value;
    -moz-background-size: $value;
    -o-background-size: $value;
    background-size: $value;
}

@mixin grayscale($value: 100%) {
    -webkit-filter: grayscale($value);
    -moz-filter: grayscale($value);
    -ms-filter: grayscale($value);
    -o-filter: grayscale($value);
    filter: grayscale($value);
    filter: gray; /* IE 6-9 */
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
}

@mixin filter($value) {
    -webkit-filter: $value;
    -moz-filter: $value;
    -o-filter: $value;
    -ms-filter: $value;
    filter: $value;
}

@mixin hyphens($value: auto) {
    -moz-hyphens: $value;
    -o-hyphens: $value;
    -webkit-hyphens: $value;
    -ms-hyphens: $value;
    hyphens: $value;
}

@mixin column-count($count) {
    -webkit-column-count: $count;
    -moz-column-count: $count;
    column-count: $count;
}

@mixin column-gap($gap) {
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin column-width($width) {
    -webkit-column-width: $width;
    -moz-column-width: $width;
    column-width: $width;
}

@mixin animation($arguments) {
    -webkit-animation: $arguments;
    -moz-animation: $arguments;
    -ms-animation: $arguments;
    -o-animation: $arguments;
    animation: $arguments;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transition-delay($string) {
    -webkit-transition-delay: $string;
    -moz-transition-delay: $string;
    -ms-transition-delay: $string;
    -o-transition-delay: $string;
    transition-delay: $string;
}

@mixin transition-duration($time) {
    -webkit-transition-duration: $time;
    -moz-transition-duration: $time;
    -ms-transition-duration: $time;
    -o-transition-duration: $time;
    transition-duration: $time;
}

@mixin transition-property($string) {
    -webkit-transition-property: $string;
    -moz-transition-property: $string;
    -o-transition-property: $string;
    transition-property: $string;
}

@mixin transform($string) {
    -webkit-transform: $string;
    -moz-transform: $string;
    -ms-transform: $string;
    -o-transform: $string;
    transform: $string;
}

@mixin transform-style($string) {
    -webkit-transform-style: $string;
    -moz-transform-style: $string;
    -ms-transform-style: $string;
    -o-transform-style: $string;
    transform-style: $string;
}

@mixin scale($factor) {
    -webkit-transform: scale($factor);
    -moz-transform: scale($factor);
    -ms-transform: scale($factor);
    -o-transform: scale($factor);
    transform: scale($factor);
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    -o-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin skew($deg, $deg2) {
    -webkit-transform: skew($deg, $deg2);
    -moz-transform: skew($deg, $deg2);
    -ms-transform: skew($deg, $deg2);
    -o-transform: skew($deg, $deg2);
    transform: skew($deg, $deg2);
}

@mixin translate($x, $y: 0) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translate3d($x, $y: 0, $z: 0) {
    -webkit-transform: translate3d($x, $y, $z);
    -moz-transform: translate3d($x, $y, $z);
    -ms-transform: translate3d($x, $y, $z);
    -o-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin perspective($value: 1000) {
    -webkit-perspective: $value;
    -moz-perspective: $value;
    -ms-perspective: $value;
    perspective: $value;
}

@mixin transform-origin($x: center, $y: center) {
    -webkit-transform-origin: $x $y;
    -moz-transform-origin: $x $y;
    -ms-transform-origin: $x $y;
    -o-transform-origin: $x $y;
    transform-origin: $x $y;
}

@mixin hide-scrollbar() {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

// FOR IMAGES
// *******************************************
@mixin object-fit($fit: cover) {
    // fill, contain, cover, scale-down, none
    -o-object-fit: $fit;
    object-fit: $fit;
}

@mixin object-position($posX: center, $posY: center) {
    // fill, contain, cover, scale-down, none
    -o-object-position: $posX $posY;
    object-position: $posX $posY;
}

@mixin object-fit-image($fit: cover, $posX: center, $posY: center) {
    -o-object-fit: $fit;
    object-fit: $fit;
    -o-object-position: $posX $posY;
    object-position: $posX $posY;
    font-family: "object-fit: #{$fit}; object-position: #{$posX} #{$posY};"; // Fix für Polyfill IE11
    height: 100%;
    width: 100%;
}

// Placeholder Style
// ------------------------------------------
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder-style {
    @include optional-at-root("::-webkit-input-placeholder") {
        @content;
    }

    @include optional-at-root(":-moz-placeholder") {
        @content;
    }

    @include optional-at-root("::-moz-placeholder") {
        @content;
    }

    @include optional-at-root(":-ms-input-placeholder") {
        @content;
    }
}

// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
    -webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
    flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
    -webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
    flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    -ms-flex-align: $align;
    align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
    -webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
    align-self: $align;
}
