// *******************************************************************
//
//		LISTS
//
// *******************************************************************

ul {
    margin: 0;
    padding: 0;

    li {
        line-height: normal;
        position: relative;
        list-style-type: none;

        ~ li {
            margin-top: .35em;
        }

        &.empty,
        &.spacer {
            height: 10px;

            &.big {
                height: 25px;
            }
        }

        &.icons {
            @include flexbox;
            @include flex-flow(wrap row);
            gap: 10px;

            a {
                font-size: 16px;
                display: inline-block;

                &:hover {
                    i.fa-facebook {
                        color: #1877f2;
                    }

                    i.fa-twitter {
                        color: #1da1f2;
                    }

                    i.fa-xing {
                        color: #03a5f0;
                    }

                    i.fa-linkedin-in {
                        color: #0a66c2;
                    }

                    i.fa-instagram {
                        color: #c32aa3;
                    }
                }
            }
        }

        &.icon {

            padding-left: 1.75em;

            &::before {
                font-family: $icon-fa;
                position: absolute;
                left: 0;
                top: 2px;
                font-size: 1em;
                text-align: center;
                width: 1.25em;
                font-weight: 300;
            }

            > span.tooltiphelper {
                display: block;
                height: 18px;
                width: 18px;
                position: absolute;
                left: 0;
                top: 0;
                @include border-radius(50%);
                z-index: 10;
            }
        }

        // end &.icon
    }

    &.list-arrow {

        li {
            position: relative;
            padding-left: 22px;

            ~ li {
                margin-top: .5em;
            }

            &::before {
                content: '\f061';
                font-family: $icon-fa;
                position: absolute;
                font-size: .95em;
                left: 0;
                top: 3px;
                color: $color-loop;
            }
        }
    }


    + button, + a.btn-loop {
        margin-top: 1.5em;
    }
}


// *******************************************************************
//
//		RESPONSIVE
//
// *******************************************************************
@media screen and (max-width: $media-breakpoint-phone) {
    ul {

    }
}


