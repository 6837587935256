// ****************************************************
//
// 		RESPONSIVE -> Phone
//
// ****************************************************


@media screen and (max-width: $media-breakpoint-phone) {

    :root {
        --flex-gap: var(--padding-box);
    }

    #sidebar-products-filters {
        .p-sidebar-right {
            width: 100% !important;
            max-width: 90%;
        }
    }
}


@media screen and (max-width: $media-breakpoint-phone-small) {
    :root {
        --padding-box: 15px;
    }
}
