// *******************************************************************
//
//		CUSTOM STYLES NOR PRIMENG COMPONENTS
//
// *******************************************************************


// Tooltips
// ****************************************************

.p-tooltip {
  font-size: .8em;
  max-width: 12em;

  .p-tooltip-text {
    font-weight: 700;
    padding: .5em 1em;
  }

  .p-tooltip-arrow {
    scale: 1;
  }

  &.nowrap {
    max-width: 100%;
  }
}

.p-tooltip-top {
  margin-top: -5px;

  .p-tooltip-arrow {
    bottom: -2px;
    left: 50%;
    @include transform(translateX(-50%));
    margin-left: 0;
    border-width: 1em 1em 0;
  }

  .p-tooltip-text {
    text-align: center;
  }
}

.p-tooltip-left {
  margin-left: -5px;

  .p-tooltip-arrow {
    top: 50%;
    @include transform(translateY(-50%));
    right: -2px;
    margin-top: 0;
    border-width: 1em 0 1em 1em;
  }
}

.p-tooltip-right {
  margin-left: 5px;

  .p-tooltip-arrow {
    top: 50%;
    @include transform(translateY(-50%));
    left: -2px;
    margin-top: 0;
    border-width: 1em 1em 1em 0;
  }
}

.p-tooltip-bottom {
  margin-top: 5px;

  .p-tooltip-arrow {
    top: -2px;
    left: 50%;
    @include transform(translateX(-50%));
    margin-left: 0;
    border-width: 0 1em 1em;
  }

  .p-tooltip-text {
    text-align: center;
  }
}

// Farben
.p-tooltip {
  &.success {
    .p-tooltip-text {
      background: $color-success;
      color: $tooltipTextColor;
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $color-success;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $color-success;
      }
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $color-success;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $color-success;
      }
    }
  }

  &.warning {
    .p-tooltip-text {
      background: $color-warning;
      color: $tooltipTextColor;
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $color-warning;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $color-warning;
      }
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $color-warning;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $color-warning;
      }
    }
  }

  &.default {
    .p-tooltip-text {
      background: $color-default;
      color: $color-text-main;
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $color-default;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $color-default;
      }
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $color-default;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $color-default;
      }
    }
  }
}

// Tabellen
// ****************************************************
.p-datatable {

  .p-datatable-tbody > tr {
    &:hover {
      background-color: $color-default-darker;
    }

    > td {
      //vertical-align: top;
      line-height: 1.3;
    }
  }
}

p-table + button {
  margin-top: 1.5em;
}


// Messages
// ****************************************************
.p-component-overlay {
  padding: var(--padding-box);
}

.p-dialog {

  .p-dialog-header {
    padding-bottom: calc($dialogHeaderPadding - 8px);

    .p-dialog-title {
      font-family: $font-heading;
      font-size: 1.7em;
      line-height: .8;
      font-weight: 400;
    }
  }

  &.dialog-medium {
    max-width: 600px;
    width: 100%;
  }

  &.no-box-shadow {
    @include box-shadow(none);
    max-height: 100%;
    overflow-y: scroll;


  }
}

.p-dialog-mask {

  &.light-black {
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(5px);
  }
}


.p-field-checkbox {
  @include flexbox;
  @include align-items(center);
  gap: 10px;

  label {
    font-weight: 700;
    line-height: 1;
  }
}

.important-message {
  background-color: $color-loop;
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 2em;

  p {
    margin: 0;
    line-height: 1.3;
    color: $tooltipTextColor;
  }
}

form.ng-submitted .ng-invalid, form.ng-submitted p-calendar.ng-invalid, form.ng-submitted p-dropdown.ng-invalid {
  border: 1px solid #e4677e !important;
}



.dashboard-error {
  margin-bottom: 3em;


  p {
    margin: 0;

    ~ p {
      margin-top: 1em;
    }
  }

  > div {
    background-color: $color-loop;
    margin: 0;

    .p-message-wrapper {
      display: block;
      padding: var(--padding-box);
    }
  }

  .p-message-summary {
    display: block;
    font-size: 2em;
    font-family: $font-heading;
    font-weight: 400;
    margin-bottom: .75em;
  }

  .p-message-detail {
    display: block;
    margin: 0;
  }

  .p-message .p-message-icon {
    display: none;
  }
}

.p-confirm-dialog.yesnodialog {
  max-width: 350px;

  .p-dialog-header,
  .p-dialog-footer {
    border: 0;
  }

  .p-dialog-header {
    position: relative;
    padding: 0;
    height: 40px;

    .icon {
      height: 90px;
      width: 90px;
      position: absolute;
      background-color: $color-loop;
      @include border-radius(50%);
      left: 50%;
      top: 0;
      @include transform(translateX(-50%) translateY(-50%));
      text-align: center;
      color: white;
      line-height: 90px;
      font-size: 3em;
    }
  }

  .p-dialog-footer {
    padding-top: 0;

    .buttons {
      @include flexbox;
      gap: 10px;
      @include justify-content(center);

      button {
        margin: 0;
      }
    }
  }

  .p-dialog-content {
    padding: 2em 1em;

    .p-confirm-dialog-message {
      margin: 0;
      font-weight: 700;
      font-size: 1.4em;
      line-height: 1.2;
      text-align: center;
    }
  }

}


// scrollPanel
// ****************************************************
.p-scrollpanel {

  .p-scrollpanel-bar {
    display: none !important;
    visibility: hidden !important;
  }

  .p-scrollpanel-wrapper {
    position: relative;
    float: none;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      left: 0;
      right: 12px;
      bottom: 0;
      height: 50px;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    .p-scrollpanel-content {
      width: 100%;
      height: 100%;
      padding: 0 12px 40px 0;
      overflow: hidden;
      overflow-y: scroll;


      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background: $color-default;
        @include border-radius(6px);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darken($color-default, 15%);
        @include border-radius(6px);
      }

    }
  }
}


// tabViewPanel
// ****************************************************
.p-tabview.scheduling {

  .p-tabview-nav {
    background: none;
    border: none;
    gap: 8px;

    li {
      margin: 0;
    }

    .p-tabview-nav-link {
      margin: 0;
      background-color: white;
      border: 1px solid $color-default;
      font-weight: bold;
      padding: .35em 1.15em;
      @include border-radius(30px);
      display: inline-block;
      white-space: nowrap;

      &::before {
        content: '\e0d2';
        font-family: $icon-fa;
        font-weight: 300;
        margin-right: 6px;
      }

      &[aria-selected=true] {
        background-color: black;
        color: white;

        &::before {
          font-weight: 600;
        }
      }
    }
  }

  .p-tabview-panels {
    padding: 1em 0 0;
  }
}
