// *******************************************************************
//
//		APP Variables
//
// *******************************************************************


// PrimeNG Base Theme Specific Variables
// **********************************************
$primaryColor: #0f0f0f;
$primaryDarkColor: #333333;
$primaryDarkerColor: black;
$primaryTextColor: #ffffff;

$secondaryColor: #791a17;


// XDPRO Theme Variables
// **********************************************
$color-primary: $primaryColor;
$color-secondary: $secondaryColor;
$color-third: #dfdfdf;
//$color-default: #eaeaea;
$color-default: #f2f2f2;
$color-default-darker: #f2f2f2;
$color-text-main: #444444;
$color-success: #59c059;


//$loopColor: #e1007a;
$color-loop: #e1007a;
.color-loop {
    color: $color-loop
}

$color-loop-darker: #8d004d;

$color-alarm: red;
$color-danger: red;
$color-warning: orange;

$border-radius-small: 6px;
$border-radius-medium: 12px;
$border-radius-form-input: 5px;


// Responsive Breakpoints
// **********************************************

$media-breakpoint-desktop-small: 1200px;
$media-breakpoint-tablet: 900px;
$media-breakpoint-tablet-small: 768px;
$media-breakpoint-phone: 480px;
$media-breakpoint-phone-small: 380px;


// PrimeNG Components -> loop+ Theme
// (diese können angepasst werden)
// **********************************************
@import 'primeng/core/general';
@import 'primeng/core/form';
@import 'primeng/core/button';
@import 'primeng/core/panel';
@import 'primeng/core/_data';
@import 'primeng/core/_overlay';
@import 'primeng/core/_message';
@import 'primeng/core/_menu';
@import 'primeng/core/_media';
@import 'primeng/core/_misc';


// Root Variables
// ****************************************************************
:root {
    font-family: "Assistant", -apple-system, Helvetica, Arial, sans-serif;
    --font-family: "Assistant", -apple-system, Helvetica, Arial, sans-serif;

    --height-navbar-top: 44px;
    --width-sidenav: 220px;
    --padding-box: clamp(25px, 2.5vw, 40px);
    --border-radius-big: 10px;
    --border-main-style: 1px solid #e0e0e0;
    //--border-radius-small: #{$borderRadius};
    --flex-gap: 20px;

    --fz-small: 80%;
    //--fz-base: clamp(14px, 2.25vw, 15px);
    --fz-base: 15px;
    --fz-h1: clamp(2em, 2.8vw, 4em);
    --fz-h2: clamp(1.85em, 2.4vw, 3.6em);
    --fz-h3: clamp(1.75em, 2.25vw, 2.15em);
    --fz-h4: clamp(1.4em, 1.75vw, 1.75em);
    --fz-h5: clamp(2.8em, 2vw, 3.5em);

    --maskbg: #{$maskBg};


    //--content-padding: #{$panelContentPadding};
    //--inline-spacing: #{$inlineSpacing};
    //--highlight-bg: #{$highlightBg};
    //--highlight-text-color: #{$highlightTextColor};
    //--focus-ring: #{$focusShadow};
}


// Font Main
$font-main: var(--font-family);
$font-main-weight: 400;
$font-main-weight-bold: 700;
$font-main-line-height: 100%; // 28px = 2.8rem (Reset durch HTML und BODY Hack)

// Font Headlines
$font-heading: "Bebas Neue", $font-main;
$font-heading-weight: 400;
$font-heading-line-height: normal;

// Fontawesome
$icon-fa: "Font Awesome 6 Pro";
