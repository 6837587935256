// *******************************************************************
//
//		TYPO
//
// *******************************************************************

#page .container div[class^="box"] .title {
    font-family: $font-heading;
    font-size: var(--fz-h3);
    line-height: .85;
    color: #444444;
    position: relative;
    padding-bottom: .1em;
    margin-bottom: .85em;

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 150px;
        height: 1px;
        background-color: $color-third;
    }
}

span.badge {
    position: absolute;
    top: -2px;
    right: -15px;
    height: 13px;
    width: 13px;
    text-align: center;
    line-height: 13px;
    font-size: 10px;
    font-weight: 600;
    background-color: $color-third;
    color: white;
    @include border-radius(50%);
}

h1,
h2,
h3,
h4 {
    font-family: $font-heading;
    color: $color-text-main;
    font-weight: 400;
    line-height: .9;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: var(--fz-h1);
    margin-bottom: 1.5em;
    line-height: 3.5em;

    span {
        display: block;
        font-family: $font-main;
        text-transform: uppercase;
        letter-spacing: 3px;
        white-space: nowrap;
        font-size: 1.3em;
        padding-bottom: 0.5em;
        border-bottom: 1px solid $color-default;
        margin-bottom: 0.5em;
    }
}

h2 {
    font-size: var(--fz-h2);
}

h3 {
    font-size: var(--fz-h3);
}

h4 {
    font-size: var(--fz-h4);
}

h5,
h6 {
    font-size: var(--fz-h5);
}

a {
    color: $color-text-main;
}

address {
    font-family: $font-main;
    font-style: normal;
}

.app-text-box p {
    margin: 0;

    > br {
        display: none;
    }

    ~ p {
        margin-top: .75em;
    }
}
