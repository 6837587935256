// *******************************************************************
//
//		NAVIGATIONS
//
// *******************************************************************

.mobile-navigation {

    --height-mobile-navigation: var(--height-navbar-top);

    @include align-items(center);
    @include justify-content(center);
    display: none;
    order: 1;
    background-color: black;
    width: 100%;
    position: sticky;
    top: calc(var(--height-navbar-top) - 1px);
    left: 0;
    z-index: 100;
    height: var(--height-mobile-navigation);
    border-bottom: 1px solid lighten(black, 88%);
    padding: 0 1em;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        content: '';
        height: 100%;
        width: 15px;
        display: block;
        z-index: 10;
        pointer-events: none;
    }

    &::before {
        left: 1em;
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
        right: 1em;
        background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    button {
        background-color: white;
        outline: none;
        color: $color-text-main;
        padding: .25em 1em;
        @include border-radius(20px);
        cursor: pointer;
        @include transition(all .25s ease-in-out);
        white-space: nowrap;
        font-size: .85em;
        border: 1px solid lighten(black, 80%);

        &:hover {
            background-color: $color-loop;
            color: white;
            border-color: $color-loop;
        }

        &:active, &:focus {
            color: $color-text-main;
            background-color: white;
            border-color: lighten(black, 80%);
        }
    }

    ul {
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(center safe);
        overflow-x: auto;
        gap: 6px;

        @include hide-scrollbar;
    }

    li {
        @include flex-basis(auto);
        @include flex-shrink(0);
        margin: 0;

        &.empty {
            //width: 30px;
        }
    }
}


// *******************************************************************
//
//		RESPONSIVE
//
// *******************************************************************

@media screen and (max-width: $media-breakpoint-phone) {
    .mobile-navigation {
        button {
            padding: .15em .8em;
        }
    }
}
