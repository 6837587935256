// *******************************************************************
//
//		HOTEL PROFILE PAGE
//
// *******************************************************************

@import "src/resources/styles/app/elements/mobile-navigation";


#page.hotel-profil .container {

    .box-account-validation {

        &.mobile {
            display: none;
        }

        .infos {
            position: relative;

            h4 {
                background-color: $color-warning;
            }

            ul {
                background-color: fade-out($color-warning, .9);
            }

            li::before {
                content: '\f05a';
                color: $color-warning;
            }
        }

        .warnings {
            position: relative;

            h4 {
                background-color: $color-danger;
            }

            ul {
                background-color: fade-out($color-danger, .9);
            }

            li::before {
                content: '\f071';
                color: $color-danger;
            }
        }

        .content > div ~ div {
            margin-top: 2em;
        }

        h4 {
            color: white;
            padding: 10px 20px 6px 20px;
            @include border-radiuses($border-radius-small, 0, 0, $border-radius-small);
        }

        ul {
            display: block;
            position: relative;
            padding: 1.5em;
            @include border-radiuses(0, $border-radius-small, $border-radius-small, 0);

            li {
                position: relative;
                padding-left: 30px;
                @include hyphens(none);
                font-weight: 600;

                ~ li {
                    margin-top: .8em;
                }

                &::before {
                    font-family: $icon-fa;
                    left: 0;
                    position: absolute;
                    top: .1em;
                    font-size: 120%;
                    font-weight: 600;
                }
            }
        }
    }

    .col-left {

        .box-hotel-images {

            img {
                @include border-radius(3px);
            }

            // Generelles
            table tbody tr td {
                padding: 10px;
            }

            // 1. Spalte
            table tbody tr td:nth-of-type(1) {
                padding: 10px 10px 10px 15px;
                width: 40px;
            }

            // 2. Spalte = Image
            table tbody tr td:nth-of-type(2) {
                width: 140px;
            }

            // 4. Spalte = Aktionen
            table tbody tr td:nth-of-type(4) {
                width: 100px;
            }

            table tbody tr:first-of-type {
                td {
                    background-color: $color-default;

                    &:nth-of-type(3)::before {
                        content: "Title Image";
                        display: block;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: $color-secondary;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .col-right {

        .box-account-validation {
            order: 1;
        }

        .box-hotel-logo {
            order: 2;

            img {
                max-height: 150px;
            }
        }

        .box-hotel-downloads {
            order: 7
        }

        .box-hotel-airports {
            order: 6
        }

        .box-hotel-rooms-suites {
            order: 5
        }

        .box-hotel-contacts {
            order: 3
        }

        .box-hotel-users {
            order: 4
        }
    }

}


// *******************************************************************
//
//		RESPONSIVE
//
// *******************************************************************

@media screen and (max-width: $media-breakpoint-tablet) {
    #page.hotel-profil .container {
        .mobile-navigation {
            @include flexbox;
            order: inherit;
        }

        .box-account-validation.mobile {
            display: block;
        }

        .col-right {
            .box-account-validation {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $media-breakpoint-phone) {
    #page.hotel-profil .container {
        .sticky-profile-buttons {
            button {
                span.desktop-text {
                    display: none;
                }

                span.mobile-text {
                    display: inline-block;
                }
            }
        }
    }
}
