/// Padding of an inplace element
/// @group misc
$inplacePadding: $inputPadding;

/// Background of an inplace element in hover state
/// @group misc
$inplaceHoverBg: #e9ecef;

/// Text color of an inplace element in hover state
/// @group misc
$inplaceTextHoverColor: $textColor;

/// Background of a badge
/// @group misc
$badgeBg: $primaryColor;

/// Text color of a badge
/// @group misc
$badgeTextColor: $primaryTextColor;

/// Minimum width of a badge
/// @group misc
$badgeMinWidth: 1.5em;

/// Height of a badge
/// @group misc
$badgeHeight: 1.5em;

/// Font weight of a badge
/// @group misc
$badgeFontWeight: 700;

/// Font size of a badge
/// @group misc
$badgeFontSize: 0.75em;

/// Padding of a badge
/// @group misc
$tagPadding: 0.25em 0.4em;

/// Height of a progress bar
/// @group misc
$progressBarHeight: 1.5em;

/// Border of a progress bar
/// @group misc
$progressBarBorder: 0 none;

/// Background of a progress bar
/// @group misc
$progressBarBg: #dee2e6;

/// Background of a progress bar value
/// @group misc
$progressBarValueBg: $primaryColor;

/// Text color of a progress bar value
/// @group misc
$progressBarValueTextColor: $primaryTextColor;

/// Background of an avatar
/// @group misc
$avatarBg: #dee2e6;

/// Text color of an avatar
/// @group misc
$avatarTextColor: $textColor;

/// Background of a chip
/// @group misc
$chipBg: #dee2e6;

/// Text color of a chip
/// @group misc
$chipTextColor: $textColor;

/// Border radius of a chip
/// @group misc
$chipBorderRadius: 16px;

/// Background of a chip in focus state
/// @group misc
$chipFocusBg: #dee2e6 !default;

/// Color of a chip in focus state
/// @group misc
$chipFocusTextColor: $textColor !default;

/// Background of a scrollTop
/// @group misc
$scrollTopBg: rgba(0, 0, 0, 0.7);

/// Background of a scrollTop in hover state
/// @group misc
$scrollTopHoverBg: rgba(0, 0, 0, 0.8);

/// Width of a scrollTop
/// @group misc
$scrollTopWidth: 3em;

/// Height of a scrollTop
/// @group misc
$scrollTopHeight: 3em;

/// Border radius of a scrollTop
/// @group misc
$scrollTopBorderRadius: 50%;

/// Font size of a scrollTop
/// @group misc
$scrollTopFontSize: 1.5em;

/// Text color of a scrollTop
/// @group misc
$scrollTopTextColor: #f8f9fa;

/// Background of a skeleton
/// @group misc
$skeletonBg: #e9ecef;

/// Background of a skeleton animation
/// @group misc
$skeletonAnimationBg: rgba(255, 255, 255, 0.4);
