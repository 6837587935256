// *******************************************************************
//
//		ACCOUNT GENERELL STYLES
//
// *******************************************************************


// Variables for Hightlight Mode
@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes make-box-shadow-in-out {
    0% {
        @include box-shadow(0 0 80px 5px rgba(0, 0, 0, 0));
    }
    20% {
        @include box-shadow(0 0 80px 5px rgba(0, 0, 0, .5));
    }
    80% {
        @include box-shadow(0 0 80px 5px rgba(0, 0, 0, .5));
    }
    100% {
        @include box-shadow(0 0 80px 5px rgba(0, 0, 0, 0));
    }
}


#page.hotel-profil,
#page.personal-profil {
    --highlight-mode-time: 6s;
    --height-sticky-profile-buttons: calc(var(--height-navbar-top) * 2);

    .container {
        &::before {
            @include opacity(0);
        }

        position: relative;
        @include flexbox;
        @include flex-direction(row);
        @include align-items(stretch);
        background-color: #fff;
        padding-bottom: calc(var(--height-navbar-top) * 2); // für die Buttons zum speichern und der Detailansicht

        .col-left,
        .col-right {
            @include flexbox;
            @include flex-direction(column);
        }

        .col-left {
            width: 65%;

            .box-personal-mydata,
            .box-hotel-profil {
                min-height: 100%;
            }

        }

        .col-right {
            width: 35%;

            > div {
                order: 50;
            }
        }

        //.sticky-profile-buttons {
        //    position: fixed;
        //    top: var(--height-navbar-top);
        //    left: var(--width-sidenav);
        //    height: var(--height-sticky-profile-buttons);
        //    right: 0;
        //    z-index: 100;
        //    padding-inline: var(--padding-box);
        //    background-color: white;
        //    @include flexbox;
        //    @include flex-direction(row);
        //    @include align-items(center);
        //    @include transition(left .25s ease-in-out);
        //    gap: 20px;
        //    border-bottom: 1px solid #e0e0e0;
        //
        //    button {
        //        &.btn-save {
        //            background-color: $color-loop;
        //            border-color: $color-loop;
        //        }
        //    }
        //}

        .sticky-profile-buttons {
            position: fixed;
            bottom: 0;
            left: var(--width-sidenav);
            height: var(--height-sticky-profile-buttons);
            right: 0;
            z-index: 100;
            padding-inline: var(--padding-box);
            background-color: $color-primary;
            @include flexbox;
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(flex-end);
            @include transition(left .25s ease-in-out);
            gap: 20px;
            border-top: 1px solid #e0e0e0;

            button {

                background-color: $color-loop;
                font-size: 1.25em;
                border-color: $color-loop;

                &.btn-save {
                    background-color: $color-loop;
                    border-color: $color-loop;
                }
            }
        }

        // Highlighting Effekt
        // *****************************************************************
        &.highlight-mode {

            &::before {
                content: '';
                position: absolute;
                top: calc(-1.5 * var(--height-navbar-top));
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .5);
                z-index: 101;
                backdrop-filter: blur(5px);
                @include animation(fade-in-out var(--highlight-mode-time) forwards);
            }


            div[class^="box"] {
                &.highlight {
                    z-index: 110;
                    @include animation-delay(.5s);
                    @include animation(make-box-shadow-in-out var(--highlight-mode-time) forwards);
                }
            }
        }
    }

}


#page.personal-profil {

    //&.add-user,
    //&.switch-user {
    //
    //    .container {
    //        max-width: 800px;
    //        padding-top: 0;
    //
    //        div[class^=box] {
    //            border: none;
    //            width: 100%;
    //        }
    //    }
    //
    //}

    &.add-user,
    &.switch-user {
        .container {
            max-width: 100%;
            width: 100%;
        }

        div[class^=box] {
            border: none;
            width: 100%;
        }
    }

    &.switch-user {
        .container {
            padding-top: 0;
        }
    }


    &.list-user {
        .container {
            max-width: 100%;
            width: 100%;
            padding-top: 0;

            div[class^=box] {
                border: none;
                width: 100%;
            }
        }
    }
}

#page.directory-details,
#page.personal-profil {
    .box-personal-contacts {
        .contact {
            display: grid;
            position: relative;
            grid-template-columns: 76px auto;
            padding: 15px;
            border: 1px solid darken($color-default, 8%);
            line-height: normal;
            font-size: 1em;
            @include border-radiuses(
                    $border-radius-small,
                    0px,
                    0px,
                    $border-radius-small
            );

            &.important,
            &.primary {
                &::before {
                    content: attr(data-description);
                    position: absolute;
                    display: inline-block;
                    background-color: $color-primary;
                    color: white;
                    font-size: 10px;
                    line-height: 18px;
                    padding: 0 12px;
                    font-weight: 600;
                    @include border-radius(30px);
                    top: -9px;
                    right: 9px;
                }
            }

            &.important {
                background-color: $color-primary;
                @include border-radius($border-radius-small);
                margin-bottom: 15px;
                color: white;
                align-items: center;
                font-size: 1.4em;

                > i {
                    text-align: center;
                    font-size: 2em;
                    padding-right: 15px;
                }

                &::before {
                    border: 2px solid white;
                    background-color: $color-loop;
                }
            }

            &.primary {
                background-color: darken($color-default, 5%);
                @include border-radius($border-radius-small);
                margin-bottom: 15px;
            }

            figure {
                padding-right: 15px;
                text-align: center;

                img {
                    width: 60px;
                    @include border-radius(5px);
                }

                a {
                    display: block;
                }

                // Icon Darstellung wenn kein User Foto hinterlegt wurde
                > i.fa-user-headset {
                    font-size: 40px;
                    color: darken($color-default, 10%);
                    margin-top: 10px;
                }

                .no-image {
                    background-color: darken($color-default, 2%);
                    text-align: center;
                    @include border-radius($border-radius-small);
                    height: 60px;

                    i {
                        line-height: 60px;
                        font-size: 1.6em;
                        color: darken($color-default, 20%);
                    }
                }
            }

            .position {
                font-weight: 700;
                font-size: 1.05em;
            }

            ul.contacts-primary,
            ul.contacts-secondary {

                @include flexbox;
                @include flex-flow(wrap row);
                gap: 4px 10px;
                margin-top: 10px;

                a {
                    text-decoration: none;
                }

                li {
                    margin: 0;

                    i {
                        width: 15px;
                        text-align: center;
                        margin-right: .2em;
                    }
                }
            }

            button.edit-user {
                position: absolute;
                top: 50%;
                right: 0;
                margin: 0;
                font-size: .8em;
                @include transform(translateY(-50%) translateX(50%));
            }

            &:last-of-type + button {
                margin-top: 1.5em;
            }
        }

        .contact:not(.primary):not(.important) {
            + .contact {
                @include border-radius(0px);
                border-top: 0;

                &:last-of-type {
                    @include border-radiuses(
                            0px,
                            $border-radius-small,
                            $border-radius-small,
                            0
                    );
                }
            }
        }

        .scroll-box .contact {
            width: calc(100% - 12px);
        }
    }
}


// *******************************************************************
//
//		Responsive
//
// *******************************************************************

@media screen and (max-width: $media-breakpoint-desktop-small) {
    #page.hotel-profil .container,
    #page.personal-profil .container {

        .col-left {
            width: 60%;
        }

        .col-right {
            width: 40%;
        }

        .sticky-profile-buttons {
            left: 0;
        }
    }
}

@media screen and (max-width: $media-breakpoint-tablet) {
    #page.hotel-profil .container,
    #page.personal-profil .container {
        @include flex-direction(column);
        padding-top: 0;
        padding-bottom: var(--height-sticky-profile-buttons);

        .col-left,
        .col-right {
            width: 100%;
        }

        .sticky-profile-buttons {
            background-color: black;
            top: auto;
            bottom: 0;
            border: none;
            @include justify-content(center);

            button {
                background-color: $color-loop;
                border-color: $color-loop;
                font-size: 1.2em;
                padding: .5em 1em;
            }
        }
    }
}


@media screen and (max-width: $media-breakpoint-phone) {
    #page.hotel-profil .container,
    #page.personal-profil .container {
        .sticky-profile-buttons {
            background-color: transparent;
        }
    }
}

