// ****************************************************
//
// 		APP Main Styles => Export to css root folder
//
// ****************************************************

// Variablen
// ****************************************************
@import 'variables/global';
@import 'helper/mixins';
@import 'variables/primeng/custom';

// PrimeNG Component THEME-BASE-Styles
// ****************************************************
@import '../primeng/theme-base/components';

// Base Styles
// ****************************************************
@import "base/fonts";
@import "base/basics";
@import "base/forms";
@import "base/typo";
@import "base/lists";
@import "base/buttons";

// Base Styles -> Account
// ****************************************************
@import "frame/account";

// Account
// ****************************************************
@import "pages/account/generell";
@import "pages/account/hotel-profile";
@import "pages/account/personal-profile";

// Plugins
// ****************************************************
@import "node_modules/@fancyapps/ui/dist/fancybox/fancybox.css";

// Responsive
// ****************************************************
@import "responsive/desktop";
@import "responsive/tablet";
@import "responsive/phone";


/*

@import './_fonts';
@import '../../theme-base/_components';
@import './_extensions';

@import "variables";
@import "mixins";
@import "fonts";
@import "basics";
@import "typo";
@import "forms";
@import "buttons";
@import "messages";
@import "lists";
@import "layout";
@import "grids";
@import "boxes";
@import "navs";



// Directory (Hotels)
@import "directory/overview";
@import "directory/details";

// Angular
@import "components";

// Plugins
@import "@fancyapps/ui/dist/fancybox/fancybox.css";
//@import "@fancyapps/ui/dist/carousel/carousel.css";
//@import "@fancyapps/ui/dist/carousel/carousel.autoplay.css";
//@import "@fancyapps/ui/dist/carousel/carousel.thumbs.css";
//@import "@fancyapps/ui/dist/panzoom/panzoom.css";
//@import "@fancyapps/ui/dist/panzoom/panzoom.toolbar.css";

// Responsive DATA
@import "responsive/responsive";
@import "responsive/mobile";
@import "responsive/tablet";
@import "responsive/phone";

.p-invalid {
    .p-multiselect-chip {
        border-color: #e4677eff;
    }

    .p-dropdown {
        border-color: #e4677eff;
    }
}
*/
