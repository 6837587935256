// *******************************************************************
//
//		GLOBAL SETTINGS FOR FORM ELEMENTS
//
// *******************************************************************

form {
    --form-gap: .75em;
    --form-height-spacer: clamp(1.5em, 3vw, 2.2em);
    --form-height-spacer-small: 1.2em;

    ul.list-for-form {

        li {

            width: 100%;

            ~ li {
                margin-top: var(--form-gap);
            }

            .p-checkbox {
                width: auto;
            }

            .p-inputswitch {
                width: 3em;
            }

            .p-button {
                width: auto;
            }

            &.cols-2 {
                @include flexbox;
                gap: var(--form-gap);

                > div {
                    width: calc(50% - (var(--form-gap) / 2));

                    > label + p-editor > .p-editor-container {
                        margin-top: .5em;
                    }
                }
            }

            &.cols-3 {
                @include flexbox;
                gap: var(--form-gap);

                > div {
                    width: calc(100% / 3 - (2 * var(--form-gap) / 3));
                }


            }

            &.flex-wrap {
                @include flex-flow(wrap row);
            }


            &.upload {
                background: $inputBg;
                padding: 2em;
                text-align: center;
                border: $inputBorder;
                @include border-radius($borderRadius);
            }

            &.image-upload {
                img {
                    @include border-radius($borderRadius);
                }
            }

            &.user-image {
                text-align: center;
                background: $inputBg;
                padding: 1em;
                border: $inputBorder;
                @include border-radius($borderRadius);

                img {
                    max-height: 250px;
                    @include border-radius($borderRadius);
                }
            }

            &.spacer {
                display: block;
                height: var(--form-height-spacer);
                margin: 0;

                &.small {
                    height: var(--form-height-spacer-small);
                }
            }

            &.submit.block {
                text-align: center;

                .p-button {
                    width: 100%;
                    display: block;
                    font-size: 1.2em;
                }
            }

        }
    }

    .p-inputtext,
    .p-password,
    .p-dropdown,
    .p-overlay,
    .p-calendar,
    .p-multiselect,
    p-floatLabel {
        width: 100%;
    }

    .p-overlay {
        left: 3px !important;
        top: 6px !important;
        width: calc(100% - 6px);
        min-width: 0 !important;
    }

    .p-dropdown {
        .p-dropdown-filter {
            .p-inputtext {
                font-size: .85em;
                line-height: 1;
            }
        }
    }

    .p-dropdown-filter {
        .p-inputtext {
            font-size: .85em;
            line-height: 1;
            border: 1px solid red;
        }
    }

    .p-dropdown-panel {
        overflow: hidden;
    }

    .p-multiselect {
        .p-multiselect-label {
            //@include flexbox;
            //@include flex-wrap(wrap);
        }

        &.p-multiselect-chip {
            .p-multiselect-token {
                font-size: .75em;
                padding: .3em .9em;
                margin-right: 3px;
                @include transition(all .25s ease-in-out);
                background-color: darken($color-default, 6%);

                &:hover {
                    background-color: $color-text-main;
                    color: white;
                }
            }
        }
    }

    // p-InputGroup Styles
    // *************************************************
    .p-inputgroup {

        --width-inputgroup-addon: 48px;

        // In einer Group soll das Inputfeld standmäßig erstmal keinen
        // Border besitzen
        input {
            border-radius: 0;
            border-right-width: 0;
        }

        .p-inputwrapper {
            > .p-component {
                width: 100%;
            }
        }

        p-floatlabel:last-child,
        .p-float-label:last-child {
            input {
                border-top-right-radius: $border-radius-form-input;
                border-bottom-right-radius: $border-radius-form-input;
                border-right-width: 1px;
            }
        }


        .p-inputgroup-addon {
            width: var(--width-inputgroup-addon);

            + .p-float-label {
                width: calc(100% - var(--width-inputgroup-addon));
            }
        }

        .p-multiselect,
        .p-dropdown {
            @include border-radiuses($border-radius-form-input, $border-radius-form-input, 0, 0);
        }

        .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
            border: $inputBorder;
            @include border-radius($border-radius-form-input);
            background-color: white;
        }
    }

    .p-float-label.width-addon {
        input {
            @include border-radius(0 !important);
        }
    }


    &.ng-submitted {
        .ng-invalid {
            border-color: #e4677e;

            .p-editor-content {
                border: 1px solid #e4677e !important;
            }
        }

        p-dropdown.ng-invalid {
            border: 1px solid #e4677e !important;
        }

        p-multiselect.ng-invalid {
            border: 1px solid #e4677e !important;
        }

    }
}


.with-flag {
    font-weight: 600;

    &::after {
        display: inline-block;
        content: "";
        background: url(/assets/flag/flags_responsive.png) no-repeat;
        background-size: 100%;
        vertical-align: middle;
        width: 17px;
        height: 12px;
    }

    &.flag-de::after {
        background-position: 0 22.31405%;
    }

    &.flag-en::after {
        background-position: 0 92.561983%;
    }

    > i {
        margin-right: 6px;
    }
}

label.with-flag {
    &::after {
        margin-left: 10px;
    }
}

label {
    @include line-clamp(1);
}

// Editor Anpassungen
.p-editor-container {
    .p-editor-toolbar.ql-toolbar.ql-snow {
        padding: 4px;

        button {
            height: 20px;
            width: 22px;
        }
    }
}


// *******************************************************************
// Country Select

.country-select {
    .p-float-label {
        label {
            opacity: 0;
        }
    }

    .p-placeholder {
        opacity: 1;
    }
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    top: 0;
    font-size: 10px;
    padding: 0 6px;
    line-height: normal;
    left: 1.5em;
    z-index: 2;
    opacity: 1;
    background-color: white;
    @include border-radius(3px);
}


// *******************************************************************
// Floating Label Fix

.floatlabel-fix {
    .p-float-label {
        label {
            opacity: 0;
        }
    }

    .p-placeholder {
        opacity: 1;
    }

    label {
        top: 0 !important;
        font-size: 10px !important;
        padding: 0 6px !important;
        line-height: normal !important;
        left: 1rem !important;
        z-index: 2 !important;
        opacity: 1 !important;
        background-color: #fff;
    }
}
