// ****************************************************
//
// 		RESPONSIVE -> Desktop
//
// ****************************************************


@media screen and (max-width: $media-breakpoint-desktop-small) {

    #sidenav {
        @include transform(translateX(-100%));

        button.toggle {
            display: block;
        }

    }
    #topnav {
        padding-left: calc(1.2 * var(--height-navbar-top) + 15px);

        .inner {
            .logo a {
                display: block;
            }
        }
    }
    #page {
        padding-left: 0;
    }
}
