// *******************************************************************
//
//		BASICS & RESET
//
// *******************************************************************

*,
*:before,
*:after {
    //padding: 0;
    //margin: 0;
    //outline: none;

    @include box-sizing(border-box);
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
    width: auto;
    height: auto;
    hyphens: auto;
    color: $color-text-main;
    padding: 0;
    margin: 0;

    font-family: $font-main;
    line-height: $font-main-line-height;
    font-weight: $font-main-weight;
    font-size: var(--fz-base);

    /* Better Font Rendering =========== */
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;

    /* SHOW LOADER */
    &.show-loader {

        &::before {
            content: "";
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: fade-out($color-loop, .2);
            z-index: 99999;
            background-image: url('/assets/img/loader.svg');
            background-repeat: no-repeat;
            background-size: 100px;
            background-position: 50% 50%;
            backdrop-filter: blur(10px);
        }
    }

    &.p-overflow-hidden {
        &::before {
            content: "";
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: fade-out(black, .4);
            z-index: 1000;
        }
    }

    &.sidenav-open #page::before {
        z-index: 900;
        content: "";
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: fade-out(black, .4);
    }

}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


figure {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    border: none;
    height: auto;
    width: auto;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic !important;
    image-rendering: auto !important;
}

// Responsive Video
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


a {
    @include transition(all .25s ease-in-out);
    text-decoration: none;
}


// Anpassung der Schriften im Message Feld von Usercentrics
// in der Lightbox
.uc-embedding-wrapper {
    color: $color-text-main;

    h3 {
        font-weight: normal;
        font-size: 1.8em;
        line-height: 1;
        margin-bottom: 0;
    }

    .uc-embedding-buttons {
        margin-bottom: 1em;

        button {
            outline: none;
        }

        + span {
            font-size: 75%;
        }
    }

    button.uc-embedding-accept {
        background-color: $color-loop;

        &:hover {
            background-color: $color-loop-darker;
        }
    }
}

// Marker für die Maps
.mapboxgl-marker {
    cursor: pointer;
    font-size: 3em;
    margin-top: -12px;
    font-family: $icon-fa;
    color: #e6007e;
    font-weight: 900;

    &::after {
        content: "\f3c5";
    }
}


// Anpassung Fancybox Videodarstellung
.fancybox__container .has-youtube .fancybox__content,
.fancybox__container .has-vimeo .fancybox__content,
.fancybox__container .has-html5video .fancybox__content {
    max-width: 1480px !important;
    width: 100% !important;
    height: auto !important;
}
