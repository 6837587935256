// *******************************************************************
//
//		ACCOUNT PERSONAL PROFIL
//
// *******************************************************************

#page .container.personal-profil {

    .col-left {
        .box-personal-mydata {
            min-height: calc(100vh - var(--height-navbar-top));
        }
    }

    .col-right {
        position: relative;
        align-self: flex-start;

        .box-personal-image {
            img {
                max-height: 200px;
                @include border-radius(10px);
            }
        }
    }
}
