// *******************************************************************
//
//    FONTS
//
// *******************************************************************

// Loading Fonts
// url('/assets/fonts/')
// ****************************************************************

/* assistant-200 - latin */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 200;
    src: url("/assets/fonts/assistant-v7-latin-200.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/fonts/assistant-v7-latin-200.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
    url("/assets/fonts/assistant-v7-latin-200.woff2") format("woff2"),
        /* Super Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-200.woff") format("woff"),
        /* Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-200.ttf") format("truetype"),
        /* Safari, Android, iOS */
    url("/assets/fonts/assistant-v7-latin-200.svg#Assistant") format("svg"); /* Legacy iOS */
}

/* assistant-300 - latin */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/assistant-v7-latin-300.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/fonts/assistant-v7-latin-300.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
    url("/assets/fonts/assistant-v7-latin-300.woff2") format("woff2"),
        /* Super Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-300.woff") format("woff"),
        /* Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-300.ttf") format("truetype"),
        /* Safari, Android, iOS */
    url("/assets/fonts/assistant-v7-latin-300.svg#Assistant") format("svg"); /* Legacy iOS */
}

/* assistant-regular - latin */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/assistant-v7-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/fonts/assistant-v7-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
    url("/assets/fonts/assistant-v7-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-regular.woff") format("woff"),
        /* Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */
    url("/assets/fonts/assistant-v7-latin-regular.svg#Assistant") format("svg"); /* Legacy iOS */
}

/* assistant-500 - latin */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/assistant-v7-latin-500.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/fonts/assistant-v7-latin-500.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
    url("/assets/fonts/assistant-v7-latin-500.woff2") format("woff2"),
        /* Super Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-500.woff") format("woff"),
        /* Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-500.ttf") format("truetype"),
        /* Safari, Android, iOS */
    url("/assets/fonts/assistant-v7-latin-500.svg#Assistant") format("svg"); /* Legacy iOS */
}

/* assistant-600 - latin */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/assistant-v7-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/fonts/assistant-v7-latin-600.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
    url("/assets/fonts/assistant-v7-latin-600.woff2") format("woff2"),
        /* Super Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-600.woff") format("woff"),
        /* Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-600.ttf") format("truetype"),
        /* Safari, Android, iOS */
    url("/assets/fonts/assistant-v7-latin-600.svg#Assistant") format("svg"); /* Legacy iOS */
}

/* assistant-700 - latin */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/assistant-v7-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/fonts/assistant-v7-latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
    url("/assets/fonts/assistant-v7-latin-700.woff2") format("woff2"),
        /* Super Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-700.woff") format("woff"),
        /* Modern Browsers */
    url("/assets/fonts/assistant-v7-latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */
    url("/assets/fonts/assistant-v7-latin-700.svg#Assistant") format("svg"); /* Legacy iOS */
}

/* bebas-neue-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/bebas-neue/bebas-neue-v14-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('/assets/fonts/bebas-neue/bebas-neue-v14-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/thin.scss"; // 200
@import "@fortawesome/fontawesome-pro/scss/light.scss"; // 300
@import "@fortawesome/fontawesome-pro/scss/regular.scss"; // 400
@import "@fortawesome/fontawesome-pro/scss/solid.scss"; // 900
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
//@import "../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss";
