// ****************************************************
//
// 		RESPONSIVE -> Tablet
//
// ****************************************************


@media screen and (max-width: $media-breakpoint-tablet) {

    :root {
        --padding-box: 20px;
    }

}


@media screen and (max-width: 600px) {

    form ul.list-for-form {
        li.cols-2,
        li.cols-3 {
            @include flex-direction(column);

            > div {
                width: 100%;
            }
        }
    }
}
